import { MouseEventHandler } from "react";
import { CloseIcon } from "../icons";
import APIconButton from "../icons/APIconButton";
import { APRow } from "../layout";
import { APPalette } from "../utils";
import { APText } from "./APText";

export type APTagVariant = 'default' | 'info' | 'warning' | 'negative' | 'positive'

export interface APTagProps {
    /**
     * @default 'default'
     */
    variant?: 'default' | 'info' | 'warning' | 'negative' | 'positive'
    /**
     * @default 'medium' 
     */
    size?: 'small' | 'medium' | 'large'
    /**
     * @default 'default'
     */
    type?: 'default' | 'outlined'
    children: string
    onClose?: MouseEventHandler<HTMLDivElement>
    noBorder?: boolean

}

export function APTag({ size = "medium", variant = "default", type = 'default', children, onClose, noBorder = false, }: APTagProps) {

    let color, backgroundColor, iconSize, fontVariant: "paragraph-xsmall" | "paragraph-small" | "paragraph-medium", padding

    switch (size) {
        case "small":
            iconSize = 8
            fontVariant = "paragraph-xsmall"
            padding = "4px 8px"
            break;
        case "large":
            iconSize = 16
            fontVariant = "paragraph-small"
            padding = "6px 8px"
            break;
        default:
            iconSize = 16
            fontVariant = "paragraph-small"
            padding = "4px 8px"
            break;
    }

    switch (variant) {
        case "info":
            color = APPalette["info-400"]
            backgroundColor = APPalette["info-100"]
            break;
        case "warning":
            color = APPalette["warning-400"]
            backgroundColor = APPalette["warning-100"]
            break;
        case "negative":
            color = APPalette["negative-400"]
            backgroundColor = APPalette["negative-100"]
            break;
        case "positive":
            color = APPalette["positive-400"]
            backgroundColor = APPalette["positive-100"]
            break;
        default:
            color = APPalette["grey-500"]
            backgroundColor = APPalette["grey-50"]
            break;
    }

    return (
        <APRow
            gap="4px"
            mainAxisSize="min"
            style={{
                padding,
                backgroundColor,
                border: noBorder ?
                    "none"
                    : `1px solid ${variant !== "default" ?
                        type === "outlined" ? backgroundColor : APPalette[variant + "-200" as keyof APPalette]
                        :
                        type === "outlined" ? backgroundColor : APPalette["grey-200"]}`,
                borderRadius: noBorder ? "8px" : "4px",
            }}
        >

            <APText variant={fontVariant} color={color}>{children}</APText>
            {
                onClose && (
                    <APIconButton hoverColor={APPalette["grey-300"]} onClick={onClose}>
                        <CloseIcon size={iconSize} color={variant !== "default" ? color : undefined} />
                    </APIconButton>
                )
            }
        </APRow>
    )
}