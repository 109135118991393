import { RouteComponentProps, useLocation } from '@gatsbyjs/reach-router';
import { useEffect, useState } from 'react';
import Activation2Model, { HoldingType, IncomeIndexType, MaritalStatusIndexType, OccupationIndexType, PepIndexType, TaxStatus } from '../../Models/Activation2Model';
import ClientActivationBasicDetailsPage from './BasIcDetailsPage';
import AddressDetailsStepperFunction from './AddressDetails';
import BankVerificationStepperFunction from './BankVerificationPage';
import ContactDetailsStepperFunction from './ContactDetails';
import NomineeDetailsStepperFunction from './NomineeDetails';
import PersonalDetailsStepperFunction from './PersonalDetails';
import PhotoPageStepperFunction from './PhotoPage';
import SignatureStepperFunction from './SignaturePage';
import { launchUrl } from '../DigilockerKYC/common';
import DigiLockerStepperFunction from './DigiLockerPage';
import MutualFundApiServices from '../../Services/MutualFundServices';
import EsignStepper from './EsignPage';
import { APCenter, APTag, getIt, showErrorDialog, APExpanded, APColumn, JourneyStepApp, JourneySectionApp, APJourneyApp, useDeviceType, APJourneyWeb, APPadding, APSizedBox, APScrollView } from 'ap-components';

export default function ClientActivationPage(props: RouteComponentProps) {
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const token = searchParams.get("token");
    const [form, setForm] = useState<Partial<Activation2Model>>();

    if (!token) {
        return <APCenter><APTag variant='negative'>Invalid user</APTag></APCenter>
    };
    useEffect(() => { getActivationDetails(); }, []);

    async function getActivationDetails() {
        try {
            setIsLoading(true);
            const activationData = await getIt(MutualFundApiServices).getActivationDetails(token ?? '');
            setForm(activationData);
            setIsLoading(false);
        } catch (error: any) {
            showErrorDialog(error.message)
            setError(true);
            setIsLoading(false);
        }
    };

    if (error) {
        return <APCenter><APTag variant='warning'>Please refresh</APTag></APCenter>
    };

    if (isLoading || !form) {
        return <APCenter><APTag variant='default'>Loading... Please Wait...</APTag></APCenter>
    };

    function updateActivationData(updatedData: Partial<Activation2Model>) {
        setForm(updatedData);
    }


    if (!!form && form.activationWorkflowProgress == 'Complete') {
        triggerActivation(token);
        launchUrl("https://www.assetplus.in/#!/")
    }

    return (
        <APExpanded noClone key={form?.activationWorkflowProgress}>
            <APColumn mainAxisSize='max'>
                <APExpanded>
                    {form?.activationWorkflowProgress === 'Basic Details' ? <ClientActivationBasicDetailsPage updateActivationData={updateActivationData} /> : <ClientActivationStepper activationData={form} updateActivationData={updateActivationData} />}
                </APExpanded>
            </APColumn>
        </APExpanded>
    );
}

function ClientActivationStepper({ activationData, updateActivationData }: { activationData: Partial<Activation2Model>, updateActivationData: (activationData: Partial<Activation2Model>) => void }) {
    let deviceType = useDeviceType();
    let steps: (JourneyStepApp | JourneySectionApp)[] = [];

    const { taxStatus, holdingType, firstHolderHasCkyc, secondHolderHasCkyc, guardianHasCkyc } = activationData;
    const nokycGuardian = (taxStatus === '02' && !guardianHasCkyc);
    const noKycSingleHolder = (holdingType === 'SI' && taxStatus !== '02' && !firstHolderHasCkyc);
    const noKycForAnyJointHolders = holdingType === 'AS' && (!firstHolderHasCkyc || !secondHolderHasCkyc);
    if (noKycSingleHolder || nokycGuardian || noKycForAnyJointHolders) {
        steps = [
            ContactDetailsStepperFunction({ activationData, updateActivationData }),
            DigiLockerStepperFunction({ activationData, updateActivationData }),
            PersonalDetailsStepperFunction({ activationData, updateActivationData }),
            AddressDetailsStepperFunction({ activationData, updateActivationData }),
            NomineeDetailsStepperFunction({ activationData, updateActivationData }),
            PhotoPageStepperFunction({ activationData, updateActivationData }),
            SignatureStepperFunction({ activationData, updateActivationData }),
            EsignStepper({ activationData, updateActivationData }),
            BankVerificationStepperFunction({ activationData, updateActivationData })
        ];
    } else {
        steps = [
            ContactDetailsStepperFunction({ activationData, updateActivationData }),
            PersonalDetailsStepperFunction({ activationData, updateActivationData }),
            AddressDetailsStepperFunction({ activationData, updateActivationData }),
            NomineeDetailsStepperFunction({ activationData, updateActivationData }),
            SignatureStepperFunction({ activationData, updateActivationData }),
            BankVerificationStepperFunction({ activationData, updateActivationData })
        ];
    }

    switch (deviceType) {
        case "mobile":
            return <APJourneyApp
                style={{ height: "100%", maxWidth: "396px" }}
                mode='linear'
                initialStepId={activationData.activationWorkflowProgress}
                title="Mutual Fund Activation"
                description='Activate account to invest in Mutual Funds'
                steps={steps}
            />
        default:
            return (
                <APPadding>
                    <APColumn crossAxisAlignment='stretch'>
                        <APScrollView>
                            <APJourneyWeb
                                mode='non-linear'
                                initialStepId={activationData.activationWorkflowProgress}
                                title="Mutual Fund Activation"
                                description='Activate account to invest in Mutual Funds'
                                steps={steps}
                            />
                        </APScrollView>
                    </APColumn>
                </APPadding>
            )
    }
}

export async function triggerActivation(token: string) {
    return getIt(MutualFundApiServices).triggerActivation({ token })
}


export class ClientActivationResponseSchema {
    userName!: string;
    activationData?: Activation2Model;
    clientPan?: string;
}

class LabelItem {
    value: string;
    label: string;

    constructor(value: string, label: string) {
        this.value = value;
        this.label = label;
    }
}

class TaxLabelItem {
    value: TaxStatus;
    label: string;

    constructor(value: TaxStatus, label: string) {
        this.value = value;
        this.label = label;
    }
}
class HoldingLabelItem {
    value: HoldingType;
    label: string;

    constructor(value: HoldingType, label: string) {
        this.value = value;
        this.label = label;
    }
}

class MaritalStatusItem {
    value: MaritalStatusIndexType;
    label: string;

    constructor(value: MaritalStatusIndexType, label: string) {
        this.value = value;
        this.label = label;
    }
}

class OccupationItem {
    value: OccupationIndexType;
    label: string;

    constructor(value: OccupationIndexType, label: string) {
        this.value = value;
        this.label = label;
    }
}

class IncomeItem {
    value: IncomeIndexType;
    label: string;

    constructor(value: IncomeIndexType, label: string) {
        this.value = value;
        this.label = label;
    }
}

class PepItem {
    value: PepIndexType;
    label: string;

    constructor(value: PepIndexType, label: string) {
        this.value = value;
        this.label = label;
    }
}

export class Constants {
    static readonly holdingType: LabelItem[] = [
        new HoldingLabelItem("SI", "Single"),
        new HoldingLabelItem("AS", "Anyone or Survivor"),
    ];

    static readonly maritalStatusIndices: LabelItem[] = [
        new MaritalStatusItem("m1", "Married"),
        new MaritalStatusItem("m2", "Unmarried"),
        new MaritalStatusItem("m3", "Others"),
    ];

    static readonly occupationIndices: LabelItem[] = [
        new OccupationItem("a1", "Business"),
        new OccupationItem("a2", "Professional"),
        new OccupationItem("a3", "Agriculture"),
        new OccupationItem("a4", "Retired"),
        new OccupationItem("a5", "Housewife"),
        new OccupationItem("a6", "Student"),
        new OccupationItem("a7", "Doctor"),
        new OccupationItem("a8", "Forex Dealer"),
        new OccupationItem("a9", "Private Sector"),
        new OccupationItem("a10", "Public Sector"),
        new OccupationItem("a11", "Govt Service"),
        new OccupationItem("a12", "Others"),
    ];

    static readonly taxStatus: LabelItem[] = [
        new TaxLabelItem("01", "Individual"),
        new TaxLabelItem("02", "For Minor"),
        new TaxLabelItem("21", "NRE"),
        new TaxLabelItem("24", "NRO"),
    ];

    static readonly incomeIndices = [
        new IncomeItem("i1", "Below 1 Lakh"),
        new IncomeItem("i2", "1 - 5 Lakh"),
        new IncomeItem("i3", "5 - 10 Lakh"),
        new IncomeItem("i4", "10 - 25 Lakh"),
        new IncomeItem("i5", "25 Lakh - 1 Cr"),
        new IncomeItem("i6", "Above 1 Cr"),
    ];

    static readonly pepIndices = [
        new PepItem("e1", "Not applicable"),
        new PepItem("e2", "Politically exposed person"),
        new PepItem("e3", "Related to Political exposed person"),
    ];

    static readonly nomineeRelation = [
        "Father",
        "Mother",
        "Sibling",
        "Husband",
        "Wife",
        "Son",
        "Daughter",
        "Grandson",
        "Granddaughter",
        "Grandfather",
        "Grandmother",
        "Uncle",
        "Aunt",
    ];

    static readonly ownershipOptions = [
        { label: "Self", value: "SE" },
        { label: "Spouse", value: "SP" },
        { label: "Dependent Children", value: "DC" },
        { label: "Dependent Siblings", value: "DS" },
        { label: "Dependent Parents", value: "DP" },
        { label: "Guardian", value: "GD" }
    ]

    static readonly states: LabelItem[] = [
        new LabelItem("AN", "Andaman & Nicobar"),
        new LabelItem("AR", "Arunachal Pradesh"),
        new LabelItem("AP", "Andhra Pradesh"),
        new LabelItem("AS", "Assam"),
        new LabelItem("BH", "Bihar"),
        new LabelItem("CH", "Chandigarh"),
        new LabelItem("CG", "Chhattisgarh"),
        new LabelItem("GO", "GOA"),
        new LabelItem("GU", "Gujarat"),
        new LabelItem("HA", "Haryana"),
        new LabelItem("HP", "Himachal Pradesh"),
        new LabelItem("JM", "Jammu & Kashmir"),
        new LabelItem("JK", "Jharkhand"),
        new LabelItem("KA", "Karnataka"),
        new LabelItem("KE", "Kerala"),
        new LabelItem("MP", "Madhya Pradesh"),
        new LabelItem("MA", "Maharashtra"),
        new LabelItem("MN", "Manipur"),
        new LabelItem("ME", "Meghalaya"),
        new LabelItem("MI", "Mizoram"),
        new LabelItem("NA", "Nagaland"),
        new LabelItem("ND", "New Delhi"),
        new LabelItem("OR", "Orissa"),
        new LabelItem("PO", "Pondicherry"),
        new LabelItem("PU", "Punjab"),
        new LabelItem("RA", "Rajasthan"),
        new LabelItem("SI", "Sikkim"),
        new LabelItem("TG", "Telangana"),
        new LabelItem("TN", "Tamil Nadu"),
        new LabelItem("TR", "Tripura"),
        new LabelItem("UP", "Uttar Pradesh"),
        new LabelItem("UC", "Uttaranchal"),
        new LabelItem("WB", "West Bengal"),
        new LabelItem("DN", "Dadra and Nagar Haveli"),
        new LabelItem("DD", "Daman and Diu"),
        new LabelItem("LD", "Lakshadweep"),
        new LabelItem("OH", "Others")
    ];

    static readonly accountType: LabelItem[] = [
        new LabelItem("SB", "Savings Bank"),
        new LabelItem("CB", "Current Bank"),
        new LabelItem("NE", "NRE Account"),
        new LabelItem("NO", "NRO Account")
    ];

    static readonly taxCountries = [
        new LabelItem("AF", "Afghanistan"),
        new LabelItem("AX", "Åland Islands"),
        new LabelItem("AL", "Albania"),
        new LabelItem("DZ", "Algeria"),
        new LabelItem("AS", "American Samoa"),
        new LabelItem("AD", "Andorra"),
        new LabelItem("AO", "Angola"),
        new LabelItem("AI", "Anguilla"),
        new LabelItem("AQ", "Antarctica"),
        new LabelItem("AG", "Antigua and Barbuda"),
        new LabelItem("AR", "Argentina"),
        new LabelItem("AM", "Armenia"),
        new LabelItem("AW", "Aruba"),
        new LabelItem("AU", "Australia"),
        new LabelItem("AT", "Austria"),
        new LabelItem("AZ", "Azerbaijan"),
        new LabelItem("BS", "Bahamas"),
        new LabelItem("BH", "Bahrain"),
        new LabelItem("BD", "Bangladesh"),
        new LabelItem("BB", "Barbados"),
        new LabelItem("BY", "Belarus"),
        new LabelItem("BE", "Belgium"),
        new LabelItem("BZ", "Belize"),
        new LabelItem("BJ", "Benin"),
        new LabelItem("BM", "Bermuda"),
        new LabelItem("BT", "Bhutan"),
        new LabelItem("BO", "Bolivia (Plurinational State of)"),
        new LabelItem("BQ", "Bonaire, Sint Eustatius and Saba"),
        new LabelItem("BA", "Bosnia and Herzegovina"),
        new LabelItem("BW", "Botswana"),
        new LabelItem("BV", "Bouvet Island"),
        new LabelItem("BR", "Brazil"),
        new LabelItem("IO", "British Indian Ocean Territory"),
        new LabelItem("BN", "Brunei Darussalam"),
        new LabelItem("BG", "Bulgaria"),
        new LabelItem("BF", "Burkina Faso"),
        new LabelItem("BI", "Burundi"),
        new LabelItem("CV", "Cabo Verde"),
        new LabelItem("KH", "Cambodia"),
        new LabelItem("CM", "Cameroon"),
        new LabelItem("CA", "Canada"),
        new LabelItem("KY", "Cayman Islands"),
        new LabelItem("CF", "Central African Republic"),
        new LabelItem("TD", "Chad"),
        new LabelItem("CL", "Chile"),
        new LabelItem("CN", "China"),
        new LabelItem("CX", "Christmas Island"),
        new LabelItem("CC", "Cocos (Keeling) Islands"),
        new LabelItem("CO", "Colombia"),
        new LabelItem("KM", "Comoros"),
        new LabelItem("CG", "Congo"),
        new LabelItem("CD", "Congo (Democratic Republic of the)"),
        new LabelItem("CK", "Cook Islands"),
        new LabelItem("CR", "Costa Rica"),
        new LabelItem("CI", "Côte d'Ivoire"),
        new LabelItem("HR", "Croatia"),
        new LabelItem("CU", "Cuba"),
        new LabelItem("CW", "Curaçao"),
        new LabelItem("CY", "Cyprus"),
        new LabelItem("CZ", "Czech Republic"),
        new LabelItem("DK", "Denmark"),
        new LabelItem("DJ", "Djibouti"),
        new LabelItem("DM", "Dominica"),
        new LabelItem("DO", "Dominican Republic"),
        new LabelItem("EC", "Ecuador"),
        new LabelItem("EG", "Egypt"),
        new LabelItem("SV", "El Salvador"),
        new LabelItem("GQ", "Equatorial Guinea"),
        new LabelItem("ER", "Eritrea"),
        new LabelItem("EE", "Estonia"),
        new LabelItem("ET", "Ethiopia"),
        new LabelItem("FK", "Falkland Islands (Malvinas)"),
        new LabelItem("FO", "Faroe Islands"),
        new LabelItem("FJ", "Fiji"),
        new LabelItem("FI", "Finland"),
        new LabelItem("FR", "France"),
        new LabelItem("GF", "French Guiana"),
        new LabelItem("PF", "French Polynesia"),
        new LabelItem("TF", "French Southern Territories"),
        new LabelItem("GA", "Gabon"),
        new LabelItem("GM", "Gambia"),
        new LabelItem("GE", "Georgia"),
        new LabelItem("DE", "Germany"),
        new LabelItem("GH", "Ghana"),
        new LabelItem("GI", "Gibraltar"),
        new LabelItem("GR", "Greece"),
        new LabelItem("GL", "Greenland"),
        new LabelItem("GD", "Grenada"),
        new LabelItem("GP", "Guadeloupe"),
        new LabelItem("GU", "Guam"),
        new LabelItem("GT", "Guatemala"),
        new LabelItem("GG", "Guernsey"),
        new LabelItem("GN", "Guinea"),
        new LabelItem("GW", "Guinea-Bissau"),
        new LabelItem("GY", "Guyana"),
        new LabelItem("HT", "Haiti"),
        new LabelItem("HM", "Heard Island and McDonald Islands"),
        new LabelItem("VA", "Holy See"),
        new LabelItem("HN", "Honduras"),
        new LabelItem("HK", "Hong Kong"),
        new LabelItem("HU", "Hungary"),
        new LabelItem("IS", "Iceland"),
        new LabelItem("IN", "India"),
        new LabelItem("ID", "Indonesia"),
        new LabelItem("IR", "Iran (Islamic Republic of)"),
        new LabelItem("IQ", "Iraq"),
        new LabelItem("IE", "Ireland"),
        new LabelItem("IM", "Isle of Man"),
        new LabelItem("IL", "Israel"),
        new LabelItem("IT", "Italy"),
        new LabelItem("JM", "Jamaica"),
        new LabelItem("JP", "Japan"),
        new LabelItem("JE", "Jersey"),
        new LabelItem("JO", "Jordan"),
        new LabelItem("KZ", "Kazakhstan"),
        new LabelItem("KE", "Kenya"),
        new LabelItem("KI", "Kiribati"),
        new LabelItem("KP", "Korea (Democratic People's Republic of)"),
        new LabelItem("KR", "Korea (Republic of)"),
        new LabelItem("KW", "Kuwait"),
        new LabelItem("KG", "Kyrgyzstan"),
        new LabelItem("LA", "Lao People's Democratic Republic"),
        new LabelItem("LV", "Latvia"),
        new LabelItem("LB", "Lebanon"),
        new LabelItem("LS", "Lesotho"),
        new LabelItem("LR", "Liberia"),
        new LabelItem("LY", "Libya"),
        new LabelItem("LI", "Liechtenstein"),
        new LabelItem("LT", "Lithuania"),
        new LabelItem("LU", "Luxembourg"),
        new LabelItem("MO", "Macao"),
        new LabelItem("MK", "Macedonia (the former Yugoslav Republic of)"),
        new LabelItem("MG", "Madagascar"),
        new LabelItem("MW", "Malawi"),
        new LabelItem("MY", "Malaysia"),
        new LabelItem("MV", "Maldives"),
        new LabelItem("ML", "Mali"),
        new LabelItem("MT", "Malta"),
        new LabelItem("MH", "Marshall Islands"),
        new LabelItem("MQ", "Martinique"),
        new LabelItem("MR", "Mauritania"),
        new LabelItem("MU", "Mauritius"),
        new LabelItem("YT", "Mayotte"),
        new LabelItem("MX", "Mexico"),
        new LabelItem("FM", "Micronesia (Federated States of)"),
        new LabelItem("MD", "Moldova (Republic of)"),
        new LabelItem("MC", "Monaco"),
        new LabelItem("MN", "Mongolia"),
        new LabelItem("ME", "Montenegro"),
        new LabelItem("MS", "Montserrat"),
        new LabelItem("MA", "Morocco"),
        new LabelItem("MZ", "Mozambique"),
        new LabelItem("MM", "Myanmar"),
        new LabelItem("NA", "Namibia"),
        new LabelItem("NR", "Nauru"),
        new LabelItem("NP", "Nepal"),
        new LabelItem("NL", "Netherlands"),
        new LabelItem("NC", "New Caledonia"),
        new LabelItem("NZ", "New Zealand"),
        new LabelItem("NI", "Nicaragua"),
        new LabelItem("NE", "Niger"),
        new LabelItem("NG", "Nigeria"),
        new LabelItem("NU", "Niue"),
        new LabelItem("NF", "Norfolk Island"),
        new LabelItem("MP", "Northern Mariana Islands"),
        new LabelItem("NO", "Norway"),
        new LabelItem("OM", "Oman"),
        new LabelItem("PK", "Pakistan"),
        new LabelItem("PW", "Palau"),
        new LabelItem("PS", "Palestine, State of"),
        new LabelItem("PA", "Panama"),
        new LabelItem("PG", "Papua New Guinea"),
        new LabelItem("PY", "Paraguay"),
        new LabelItem("PE", "Peru"),
        new LabelItem("PH", "Philippines"),
        new LabelItem("PN", "Pitcairn"),
        new LabelItem("PL", "Poland"),
        new LabelItem("PT", "Portugal"),
        new LabelItem("PR", "Puerto Rico"),
        new LabelItem("QA", "Qatar"),
        new LabelItem("RE", "Réunion"),
        new LabelItem("RO", "Romania"),
        new LabelItem("RU", "Russian Federation"),
        new LabelItem("RW", "Rwanda"),
        new LabelItem("BL", "Saint Barthélemy"),
        new LabelItem("SH", "Saint Helena, Ascension and Tristan da Cunha"),
        new LabelItem("KN", "Saint Kitts and Nevis"),
        new LabelItem("LC", "Saint Lucia"),
        new LabelItem("MF", "Saint Martin (French part)"),
        new LabelItem("PM", "Saint Pierre and Miquelon"),
        new LabelItem("VC", "Saint Vincent and the Grenadines"),
        new LabelItem("WS", "Samoa"),
        new LabelItem("SM", "San Marino"),
        new LabelItem("ST", "Sao Tome and Principe"),
        new LabelItem("SA", "Saudi Arabia"),
        new LabelItem("SN", "Senegal"),
        new LabelItem("RS", "Serbia"),
        new LabelItem("SC", "Seychelles"),
        new LabelItem("SL", "Sierra Leone"),
        new LabelItem("SG", "Singapore"),
        new LabelItem("SX", "Sint Maarten (Dutch part)"),
        new LabelItem("SK", "Slovakia"),
        new LabelItem("SI", "Slovenia"),
        new LabelItem("SB", "Solomon Islands"),
        new LabelItem("SO", "Somalia"),
        new LabelItem("ZA", "South Africa"),
        new LabelItem("GS", "South Georgia and the South Sandwich Islands"),
        new LabelItem("SS", "South Sudan"),
        new LabelItem("ES", "Spain"),
        new LabelItem("LK", "Sri Lanka"),
        new LabelItem("SD", "Sudan"),
        new LabelItem("SR", "Suriname"),
        new LabelItem("SJ", "Svalbard and Jan Mayen"),
        new LabelItem("SZ", "Swaziland"),
        new LabelItem("SE", "Sweden"),
        new LabelItem("CH", "Switzerland"),
        new LabelItem("SY", "Syrian Arab Republic"),
        new LabelItem("TW", "Taiwan, Province of China"),
        new LabelItem("TJ", "Tajikistan"),
        new LabelItem("TZ", "Tanzania, United Republic of"),
        new LabelItem("TH", "Thailand"),
        new LabelItem("TL", "Timor-Leste"),
        new LabelItem("TG", "Togo"),
        new LabelItem("TK", "Tokelau"),
        new LabelItem("TO", "Tonga"),
        new LabelItem("TT", "Trinidad and Tobago"),
        new LabelItem("TN", "Tunisia"),
        new LabelItem("TR", "Turkey"),
        new LabelItem("TM", "Turkmenistan"),
        new LabelItem("TC", "Turks and Caicos Islands"),
        new LabelItem("TV", "Tuvalu"),
        new LabelItem("UG", "Uganda"),
        new LabelItem("UA", "Ukraine"),
        new LabelItem("AE", "United Arab Emirates"),
        new LabelItem("GB", "United Kingdom of Great Britain and Northern Ireland"),
        new LabelItem("UM", "United States Minor Outlying Islands"),
        new LabelItem("US", "United States of America"),
        new LabelItem("UY", "Uruguay"),
        new LabelItem("UZ", "Uzbekistan"),
        new LabelItem("VU", "Vanuatu"),
        new LabelItem("VE", "Venezuela (Bolivarian Republic of)"),
        new LabelItem("VN", "Viet Nam"),
        new LabelItem("VG", "Virgin Islands (British)"),
        new LabelItem("VI", "Virgin Islands (U.S.)"),
        new LabelItem("WF", "Wallis and Futuna"),
        new LabelItem("EH", "Western Sahara"),
        new LabelItem("YE", "Yemen"),
        new LabelItem("ZM", "Zambia"),
        new LabelItem("ZW", "Zimbabwe"),
        new LabelItem("XX", "Not Categorized"),
        new LabelItem("ZZ", "Others")
    ];

    static readonly countries = [
        new LabelItem("001", "Afghanistan"),
        new LabelItem("002", "Aland Islands"),
        new LabelItem("003", "Albania"),
        new LabelItem("004", "Algeria"),
        new LabelItem("005", "American Samoa"),
        new LabelItem("006", "Andorra"),
        new LabelItem("007", "Angola"),
        new LabelItem("008", "Anguilla"),
        new LabelItem("009", "Antarctica"),
        new LabelItem("010", "Antigua And Barbuda"),
        new LabelItem("011", "Argentina"),
        new LabelItem("012", "Armenia"),
        new LabelItem("013", "Aruba"),
        new LabelItem("014", "Australia"),
        new LabelItem("015", "Austria"),
        new LabelItem("016", "Azerbaijan"),
        new LabelItem("017", "Bahamas"),
        new LabelItem("018", "Bahrain"),
        new LabelItem("019", "Bangladesh"),
        new LabelItem("020", "Barbados"),
        new LabelItem("021", "Belarus"),
        new LabelItem("022", "Belgium"),
        new LabelItem("023", "Belize"),
        new LabelItem("024", "Benin"),
        new LabelItem("025", "Bermuda"),
        new LabelItem("026", "Bhutan"),
        new LabelItem("027", "Bolivia"),
        new LabelItem("028", "Bosnia And Herzegovina"),
        new LabelItem("029", "Botswana"),
        new LabelItem("030", "Bouvet Island"),
        new LabelItem("031", "Brazil"),
        new LabelItem("032", "British Indian Ocean Territory"),
        new LabelItem("033", "Brunei Darussalam"),
        new LabelItem("034", "Bulgaria"),
        new LabelItem("035", "Burkina Faso"),
        new LabelItem("036", "Burundi"),
        new LabelItem("037", "Cambodia"),
        new LabelItem("038", "Cameroon"),
        new LabelItem("039", "Canada"),
        new LabelItem("040", "Cape Verde"),
        new LabelItem("041", "Cayman Islands"),
        new LabelItem("042", "Central African Republic"),
        new LabelItem("043", "Chad"),
        new LabelItem("044", "Chile"),
        new LabelItem("045", "China"),
        new LabelItem("046", "Christmas Island"),
        new LabelItem("047", "Cocos (Keeling) Islands"),
        new LabelItem("048", "Colombia"),
        new LabelItem("049", "Comoros"),
        new LabelItem("050", "Congo"),
        new LabelItem("051", "Congo, The Democratic Republic Of The"),
        new LabelItem("052", "Cook Islands"),
        new LabelItem("053", "Costa Rica"),
        new LabelItem("054", "Cote DIvoire"),
        new LabelItem("055", "Croatia"),
        new LabelItem("056", "Cuba"),
        new LabelItem("057", "Cyprus"),
        new LabelItem("058", "Czech Republic"),
        new LabelItem("059", "Denmark"),
        new LabelItem("060", "Djibouti"),
        new LabelItem("061", "Dominica"),
        new LabelItem("062", "Dominican Republic"),
        new LabelItem("063", "Ecuador"),
        new LabelItem("064", "Egypt"),
        new LabelItem("065", "El Salvador"),
        new LabelItem("066", "Equatorial Guinea"),
        new LabelItem("067", "Eritrea"),
        new LabelItem("068", "Estonia"),
        new LabelItem("069", "Ethiopia"),
        new LabelItem("070", "Falkland Islands (Malvinas)"),
        new LabelItem("071", "Faroe Islands"),
        new LabelItem("072", "Fiji"),
        new LabelItem("073", "Finland"),
        new LabelItem("074", "France"),
        new LabelItem("075", "French Guiana"),
        new LabelItem("076", "French Polynesia"),
        new LabelItem("077", "French Southern Territories"),
        new LabelItem("078", "Gabon"),
        new LabelItem("079", "Gambia"),
        new LabelItem("080", "Georgia"),
        new LabelItem("081", "Germany"),
        new LabelItem("082", "Ghana"),
        new LabelItem("083", "Gibraltar"),
        new LabelItem("084", "Greece"),
        new LabelItem("085", "Greenland"),
        new LabelItem("086", "Grenada"),
        new LabelItem("087", "Guadeloupe"),
        new LabelItem("088", "Guam"),
        new LabelItem("089", "Guatemala"),
        new LabelItem("090", "Guernsey"),
        new LabelItem("091", "Guinea"),
        new LabelItem("092", "Guinea-Bissau"),
        new LabelItem("093", "Guyana"),
        new LabelItem("094", "Haiti"),
        new LabelItem("095", "Heard Island And Mcdonald Islands"),
        new LabelItem("096", "Holy See (Vatican City State)"),
        new LabelItem("097", "Honduras"),
        new LabelItem("098", "Hong Kong"),
        new LabelItem("099", "Hungary"),
        new LabelItem("100", "Iceland"),
        new LabelItem("101", "India"),
        new LabelItem("102", "Indonesia"),
        new LabelItem("103", "Iran, Islamic Republic Of"),
        new LabelItem("104", "Iraq"),
        new LabelItem("105", "Ireland"),
        new LabelItem("106", "Isle Of Man"),
        new LabelItem("107", "Israel"),
        new LabelItem("108", "Italy"),
        new LabelItem("109", "Jamaica"),
        new LabelItem("110", "Japan"),
        new LabelItem("111", "Jersey"),
        new LabelItem("112", "Jordan"),
        new LabelItem("113", "Kazakhstan"),
        new LabelItem("114", "Kenya   "),
        new LabelItem("115", "Kiribati"),
        new LabelItem("116", "Korea, Democratic Peoples Republic Of"),
        new LabelItem("117", "Korea, Republic Of"),
        new LabelItem("118", "Kuwait"),
        new LabelItem("119", "Kyrgyzstan"),
        new LabelItem("120", "Lao Peoples Democratic Republic"),
        new LabelItem("121", "Latvia"),
        new LabelItem("122", "Lebanon"),
        new LabelItem("123", "Lesotho"),
        new LabelItem("124", "Liberia"),
        new LabelItem("125", "Libyan Arab Jamahiriya"),
        new LabelItem("126", "Liechtenstein"),
        new LabelItem("127", "Lithuania"),
        new LabelItem("128", "Luxembourg"),
        new LabelItem("129", "Macao"),
        new LabelItem("130", "Macedonia, The Former Yugoslav Republic Of"),
        new LabelItem("131", "Madagascar"),
        new LabelItem("132", "Malawi"),
        new LabelItem("133", "Malaysia"),
        new LabelItem("134", "Maldives"),
        new LabelItem("135", "Mali"),
        new LabelItem("136", "Malta"),
        new LabelItem("137", "Marshall Islands"),
        new LabelItem("138", "Martinique"),
        new LabelItem("139", "Mauritania"),
        new LabelItem("140", "Mauritius"),
        new LabelItem("141", "Mayotte"),
        new LabelItem("142", "Mexico"),
        new LabelItem("143", "Micronesia, Federated States Of"),
        new LabelItem("144", "Moldova, Republic Of"),
        new LabelItem("145", "Monaco"),
        new LabelItem("146", "Mongolia"),
        new LabelItem("147", "Montserrat"),
        new LabelItem("148", "Morocco"),
        new LabelItem("149", "Mozambique"),
        new LabelItem("150", "Myanmar"),
        new LabelItem("151", "Namibia"),
        new LabelItem("152", "Nauru   "),
        new LabelItem("153", "Nepal"),
        new LabelItem("154", "Netherlands"),
        new LabelItem("155", "Netherlands Antilles"),
        new LabelItem("156", "New Caledonia"),
        new LabelItem("157", "New Zealand"),
        new LabelItem("158", "Nicaragua"),
        new LabelItem("159", "Niger"),
        new LabelItem("160", "Nigeria"),
        new LabelItem("161", "Niue"),
        new LabelItem("162", "Norfolk Island"),
        new LabelItem("163", "Northern Mariana Islands"),
        new LabelItem("164", "Norway"),
        new LabelItem("165", "Oman"),
        new LabelItem("166", "Pakistan"),
        new LabelItem("167", "Palau"),
        new LabelItem("168", "Palestinian Territory, Occupied"),
        new LabelItem("169", "Panama"),
        new LabelItem("170", "Papua New Guinea"),
        new LabelItem("171", "Paraguay"),
        new LabelItem("172", "Peru"),
        new LabelItem("173", "Philippines"),
        new LabelItem("174", "Pitcairn"),
        new LabelItem("175", "Poland"),
        new LabelItem("176", "Portugal"),
        new LabelItem("177", "Puerto Rico"),
        new LabelItem("178", "Qatar"),
        new LabelItem("179", "Reunion"),
        new LabelItem("180", "Romania"),
        new LabelItem("181", "Russian Federation"),
        new LabelItem("182", "Rwanda"),
        new LabelItem("183", "Saint Helena"),
        new LabelItem("184", "Saint Kitts And Nevis"),
        new LabelItem("185", "Saint Lucia"),
        new LabelItem("186", "Saint Pierre And Miquelon"),
        new LabelItem("187", "Saint Vincent And The Grenadines"),
        new LabelItem("188", "Samoa"),
        new LabelItem("189", "San Marino"),
        new LabelItem("190", "Sao Tome And Principe"),
        new LabelItem("191", "Saudi Arabia   "),
        new LabelItem("192", "Senegal"),
        new LabelItem("193", "Serbia And Montenegro"),
        new LabelItem("194", "Seychelles"),
        new LabelItem("195", "Sierra Leone"),
        new LabelItem("196", "Singapore"),
        new LabelItem("197", "Slovakia"),
        new LabelItem("198", "Slovenia"),
        new LabelItem("199", "Solomon Islands"),
        new LabelItem("200", "Somalia"),
        new LabelItem("201", "South Africa"),
        new LabelItem("202", "South Georgia And The South Sandwich Islands"),
        new LabelItem("203", "Spain"),
        new LabelItem("204", "Sri Lanka"),
        new LabelItem("205", "Sudan"),
        new LabelItem("206", "Suriname"),
        new LabelItem("207", "Svalbard And Jan Mayen"),
        new LabelItem("208", "Swaziland"),
        new LabelItem("209", "Sweden"),
        new LabelItem("210", "Switzerland"),
        new LabelItem("211", "Syrian Arab Republic"),
        new LabelItem("212", "Taiwan, Province Of China"),
        new LabelItem("213", "Tajikistan"),
        new LabelItem("214", "Tanzania, United Republic Of"),
        new LabelItem("215", "Thailand"),
        new LabelItem("216", "Timor-Leste"),
        new LabelItem("217", "Togo"),
        new LabelItem("218", "Tokelau"),
        new LabelItem("219", "Tonga"),
        new LabelItem("220", "Trinidad And Tobago"),
        new LabelItem("221", "Tunisia"),
        new LabelItem("222", "Turkey"),
        new LabelItem("223", "Turkmenistan"),
        new LabelItem("224", "Turks And Caicos Islands"),
        new LabelItem("225", "Tuvalu"),
        new LabelItem("226", "Uganda"),
        new LabelItem("227", "Ukraine"),
        new LabelItem("228", "United Arab Emirates"),
        new LabelItem("229", "United Kingdom"),
        new LabelItem("230", "United States of America"),
        new LabelItem("231", "United States Minor Outlying Islands"),
        new LabelItem("232", "Uruguay"),
        new LabelItem("233", "Uzbekistan"),
        new LabelItem("234", "Vanuatu"),
        new LabelItem("235", "Venezuela"),
        new LabelItem("236", "Viet Nam"),
        new LabelItem("237", "Virgin Islands, British"),
        new LabelItem("238", "Virgin Islands, U.S."),
        new LabelItem("239", "Wallis And Futuna"),
        new LabelItem("240", "Western Sahara"),
        new LabelItem("241", "Yemen"),
        new LabelItem("242", "Zambia"),
        new LabelItem("243", "Zimbabwe")
    ];

    static readonly taxIdTypes = [
        new LabelItem("A", "Passport"),
        new LabelItem("B", "Election ID Card"),
        new LabelItem("C", "PAN Card"),
        new LabelItem("D", "ID Card"),
        new LabelItem("E", "Driving License"),
        new LabelItem("G", "UIDIA / Aadhar letter"),
        new LabelItem("H", "NREGA Job Card"),
        new LabelItem("O", "Others"),
        new LabelItem("X", "Not categorized"),
        new LabelItem("T", "TIN [Tax Payer Identification Number]"),
        new LabelItem("C1", "Company Identification Number"),
        new LabelItem("G1", "US GIIN"),
        new LabelItem("E1", "Global Entity Identification Number")
    ];

    static readonly gender = [
        new LabelItem("M", "Male"),
        new LabelItem("F", "Female"),
    ];
};

export interface CheckPanDetailsClass {
    panNumber: string;
    dob: string;
}

export type DefaultObjectType = { [key: string]: any }